import Image from "next/image";

import UILink from "@/components/ui/link";
import type { Person } from "@/types";

interface AuthorTooltipProps {
	author: Person;
	showImage?: boolean;
}

const AuthorTooltip = ({ author, showImage = false }: AuthorTooltipProps) => (
	<div>
		<h6>{author.degree_short ? `${author.name}, ${author.degree_short}` : author.name}</h6>
		<p>
			<span>
				{showImage && (
					<Image
						src={author.image}
						width={90}
						height={90}
						alt={author.name}
						className="float-left mr-2 mt-1.5 aspect-square rounded-md object-cover"
					/>
				)}
				{author.about}
			</span>
		</p>
		{author.has_expert_page && author.slug && (
			<UILink href={`/experts/${author.slug}/`}>Read more</UILink>
		)}
	</div>
);

export default AuthorTooltip;
