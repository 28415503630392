import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { getCookie, hasCookie } from "cookies-next";
import { Ellipsis, LinkIcon, Mail } from "lucide-react";
import { usePathname } from "next/navigation";
import * as React from "react";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	XIcon,
} from "react-share";
import useSWR from "swr";

import { LoginLink } from "@/components/login-link";
import { Button, buttonVariants } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import Link from "@/components/ui/link";
import { toast } from "@/components/ui/use-toast";
import { useUser } from "@/lib/hooks/auth";
import { swrFetcher } from "@/lib/hooks/fetch-client";
import { useEnterprise } from "@/lib/hooks/use-enterprise";
import { useSubscription } from "@/lib/hooks/use-subscription";
import { cn } from "@/lib/utils";
import { SITE_URL } from "@/lib/utils/index";
import { User } from "@/types/index";

interface ShareButtonsProps {
	shareLink: string;
	desc?: string;
}

interface ReferEarnProps extends React.HTMLAttributes<HTMLDivElement> {
	url: string;
	children: React.ReactNode;
	triggerClassNames?: string;
	desc?: string;
}

const ShareButtons = ({ shareLink, desc }: ShareButtonsProps) => {
	const linkRef = React.useRef(null);
	const getLink = () => {
		if (shareLink?.startsWith("http")) {
			return shareLink;
		}
		return `${SITE_URL}${shareLink}`;
	};

	const trackShare = async (network: string) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer?.push({
			event: "share",
			method: network,
			shareLink,
		});
	};

	const copyLink = async (e: React.MouseEvent<HTMLElement>) => {
		const link = getLink();
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(link);
		} else {
			linkRef.current.select();
			document.execCommand("copy");
			(e.target as HTMLButtonElement).focus();
		}

		trackShare("Copy");
		toast({ title: "Copied!" });
	};

	const shareData = {
		title: "Examine.com",
		text: desc?.replace(/<[^>]*>?/gm, ""),
		url: getLink(),
	};

	const nativeShare = async () => {
		if (navigator?.share) {
			await navigator
				.share(shareData)
				.then(() => {
					trackShare("Native");
				})
				.catch((error) => {});
		}
	};

	const styles = {
		shareButton: cn(
			buttonVariants({ variant: "ghost" }),
			`flex flex-col gap-y-1.5 py-2 justify-between items-center group transition-transform duration-200 hover:-translate-y-1`
		),
		label: `text-sm font-medium text-black group-hover:underline whitespace-nowrap`,
		iconWrapper: `rounded-full bg-primary p-2 text-white`,
	};

	return (
		<div className="mb-6 mt-2 grid grid-cols-4 gap-2 sm:gap-3 md:grid-cols-5">
			<Button className={cn(styles.shareButton)} onClick={copyLink}>
				<div className={cn(styles.iconWrapper, "bg-examine-green-700")}>
					<LinkIcon size={18} />
				</div>
				<span className={styles.label}>Copy link</span>
				<Input readOnly={true} ref={linkRef} type="hidden" value={getLink()} />
			</Button>

			<Link
				className={styles.shareButton}
				onClick={() => trackShare("Mail")}
				href={`mailto:?body=${encodeURIComponent(`${shareData.text}\n${shareData.url}`)}`}
				target="_blank"
			>
				<div className={cn(styles.iconWrapper, "bg-primary")}>
					<Mail size={18} />
				</div>
				<span className={styles.label}>Email</span>
			</Link>

			<FacebookShareButton url={getLink()} onClick={() => trackShare("Facebook")}>
				<div className={cn(styles.shareButton)}>
					<FacebookIcon round={true} size={34} />
					<span className={styles.label}>Facebook</span>
				</div>
			</FacebookShareButton>

			<TwitterShareButton
				className={styles.shareButton}
				url={getLink()}
				via="Examinecom"
				onClick={() => trackShare("Twitter")}
			>
				<div className={cn(styles.shareButton)}>
					<XIcon round={true} size={34} />
					<span className={styles.label}>Twitter</span>
				</div>
			</TwitterShareButton>

			<LinkedinShareButton
				className={styles.shareButton}
				url={getLink()}
				source={SITE_URL}
				onClick={() => trackShare("Linkedin")}
			>
				<div className={cn(styles.shareButton)}>
					<LinkedinIcon round={true} size={34} />
					<span className={styles.label}>LinkedIn</span>
				</div>
			</LinkedinShareButton>

			<WhatsappShareButton
				className={styles.shareButton}
				url={getLink()}
				onClick={() => trackShare("Whatsapp")}
			>
				<div className={cn(styles.shareButton)}>
					<WhatsappIcon round={true} size={34} />
					<span className={styles.label}>WhatsApp</span>
				</div>
			</WhatsappShareButton>

			{navigator?.share && (
				<Button className={cn(styles.shareButton)} onClick={nativeShare}>
					<div className={cn(styles.iconWrapper, "bg-examine-purple-100 text-black")}>
						<Ellipsis size={18} />
					</div>
					<span className={styles.label}>More</span>
				</Button>
			)}
		</div>
	);
};

export function ReferEarnOld({ closeModal, desc }: any) {
	const { user } = useUser();
	const { isEnterpriseUser } = useSubscription();
	const pathname = usePathname();

	let shareLink = pathname;
	let addSocial = true;
	if (user && user.affiliate_info?.code && !isEnterpriseUser) {
		shareLink = `${process.env.NEXT_PUBLIC_SITE_URL}/refer/${user.affiliate_info?.code}`;
		shareLink += `?loc=${pathname}`;
		addSocial = false;
	}

	if (isEnterpriseUser) {
		return (
			<div className="relative mb-10 max-w-sm rounded-md bg-white p-6 pt-10 shadow-xl sm:p-8 sm:pt-12 md:max-w-md xl:max-w-xl">
				<a
					className="absolute right-5 top-3 cursor-pointer sm:right-6 sm:top-4"
					onClick={closeModal}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={2}
						stroke="currentColor"
						className="size-6 sm:size-7"
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</a>

				<ShareButtons shareLink={shareLink} desc={desc} />
			</div>
		);
	}
}

export default function ReferEarn({ url, desc = "", children }: ReferEarnProps) {
	const token = hasCookie("token") ? getCookie("token") : null;
	const { data: user } = useSWR<User>(token ? ["/v1/user", token] : null, swrFetcher);
	const { data: profile } = useSWR(token ? ["/v1/affiliate/profile", token] : null, swrFetcher);
	const referralRef = React.useRef(null);
	const [addSocial, setAddSocial] = React.useState(true);
	const [shareLink, setShareLink] = React.useState("");
	const { isEnterpriseUser } = useEnterprise();
	const referralLink = `${process.env.NEXT_PUBLIC_SITE_URL}/refer/${profile?.code}`;

	React.useEffect(() => {
		const getURI = () => {
			if (url.startsWith("http")) {
				return "/" + url.split("/").splice(3).join("/");
			}

			return url;
		};

		if (user && user?.affiliate_info?.code && !isEnterpriseUser) {
			let link = `${process.env.NEXT_PUBLIC_SITE_URL}/refer/${user.affiliate_info?.code}`;
			if (url) {
				link = `${link}?loc=${getURI()}`;
			}
			setShareLink(link);
			setAddSocial(true);
		} else {
			setShareLink(`${url}/`);
			setAddSocial(true);
		}
	}, [user, isEnterpriseUser, url]);

	const copy = async (e: React.MouseEvent<HTMLElement>) => {
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(shareLink);
		} else {
			referralRef.current.select();
			document.execCommand("copy");
			(e.target as HTMLButtonElement).focus();
		}

		toast({ title: "Copied!" });
	};

	return (
		<Dialog>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle className="text-left text-xl font-semibold">Share this page:</DialogTitle>
					<VisuallyHidden>
						<DialogDescription>Share this page</DialogDescription>
					</VisuallyHidden>
				</DialogHeader>

				{isEnterpriseUser ? (
					<ShareButtons shareLink={shareLink} desc={desc} />
				) : (
					<section>
						{addSocial ? <ShareButtons shareLink={shareLink} desc={desc} /> : null}
						<div className={cn(addSocial ? "border-t border-gray-300 pt-5" : "", "my-4")}>
							{user ? (
								<>
									<p className="mb-5">
										Share this page with a friend and earn a 33% commission if they sign up for
										Examine+ through your unique referral link!{" "}
										<Link href="/members/myreferrals/" className="font-bold underline">
											Click here
										</Link>{" "}
										to{" "}
										{user?.affiliate_info?.code
											? "see your referral stats and to learn more"
											: "set your referral code and to learn more"}
										.
									</p>
									{profile?.code ? (
										<div className="flex overflow-hidden rounded border border-examine-green-500">
											<Input
												readOnly={true}
												ref={referralRef}
												type="text"
												value={shareLink}
												className="flex-auto border-0 outline-0"
											/>
											<Button
												className="rounded-l-none bg-examine-green-500 px-5 py-2 text-white"
												onClick={(e) => copy(e)}
											>
												Copy
											</Button>
										</div>
									) : null}
								</>
							) : (
								<p>
									Earn 33% commission for life if you share this page and they sign up for Examine+!{" "}
									<Link href="/signup/" className="font-semibold underline">
										Sign up with a free account
									</Link>{" "}
									or{" "}
									<LoginLink className="font-semibold text-examine-purple-400 underline">
										log in
									</LoginLink>{" "}
									for your unique referral link.{" "}
									<Link href="/referral-program/" className="font-semibold underline">
										Learn more
									</Link>
									.
								</p>
							)}
						</div>
					</section>
				)}
			</DialogContent>
		</Dialog>
	);
}
