"use client";

import Link from "next/link";

import { ExaminePlusLink } from "@/components/cta";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import { LoginLink } from "@/components/login-link";
import SaleWrapper from "@/components/sale-wrapper";
import Trial from "@/components/trial";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { percentOff, saleLabel } from "@/lib/data/sales";
import { cn } from "@/lib/utils";

interface LockIconProps {
	size?: number;
	unlocked?: boolean;
	className?: string;
	noHover?: boolean;
}

interface LockBadgeProps {
	size?: number;
	tooltip?: React.ReactNode;
	className?: string;
	trackEvent?: () => void;
}

interface LockBadgeOverlayProps {
	size?: number;
	text: React.ReactNode;
	className?: string;
}

const LockIcon = ({ size, unlocked, className, noHover }: LockIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={cn(!noHover && "group", `h-${size || "7"} w-${size || "7"}`, className)}
			viewBox="0 0 13.72 13.72"
		>
			<g>
				<g>
					<rect fill="#6e488c" x="3.9" y="5.43" width="5.92" height="4.99" rx="0.77" />
					<path
						className={cn("group-hover:hidden", unlocked ? "hidden" : "")}
						fill="none"
						stroke="#6e488c"
						strokeMiterlimit="10"
						strokeWidth="0.62px"
						d="M8.4,4.25a1.54,1.54,0,0,0-3.08,0V5.82H8.4Z"
					/>
					<path
						className={cn("group-hover:block", unlocked ? "" : "hidden")}
						fill="none"
						stroke="#6e488c"
						strokeMiterlimit="10"
						strokeWidth="0.62px"
						d="M8.4,4.25a1.54,1.54,0,0,0-3.08,0V5.82"
					/>
				</g>
			</g>
		</svg>
	);
};

const LockBadge = ({ size, tooltip, className, trackEvent = () => {} }: LockBadgeProps) => {
	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<span
					className={cn("inline-block rounded-md bg-[#d7cedf]", className)}
					onClick={trackEvent}
				>
					<LockIcon size={size} className="inline-block" />
				</span>
			</TooltipTrigger>
			{tooltip && <TooltipContent>{tooltip}</TooltipContent>}
		</Tooltip>
	);
};

const LockBadgeOverlay = ({ size, text, className }: LockBadgeOverlayProps) => {
	return (
		<span
			className={cn(
				"group absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center rounded-md bg-white p-5 drop-shadow-md",
				className
			)}
		>
			<LockBadge
				size={size}
				className="mr-5 mt-1.5 hidden self-start [@media(min-width:500px)]:inline-block"
			/>
			{text}
		</span>
	);
};

const SSOverlayCta = ({ location = "" }) => {
	return (
		<span className="pointer-events-auto">
			<b>
				<LockBadge
					size={8}
					className="mr-2 inline-block self-start [@media(min-width:500px)]:hidden"
				/>
				You must be{" "}
				<LoginLink className="text-primary !underline hover:opacity-60">logged in</LoginLink> to
				view the full text of this Study Summary.
			</b>
			<p className="w-full py-5 text-lg xl:text-xl [@media(min-width:500px)]:w-[30ch]">
				If you do not have an account, please{" "}
				<Link href="/signup" className="text-primary !underline hover:opacity-60">
					click here to create a free account
				</Link>
				.
				<br />
				<br />
				If you want to stay on top of the latest research,{" "}
				<SaleWrapper
					cta={
						<ExaminePlusLink
							trackDetails={{
								location,
								label: "try out Examine+ free for 7 days",
							}}
							className="text-primary !underline hover:opacity-60"
						>
							<FreeTrialWrapper cta={"get started with Examine+ today"}>
								try out Examine+ free for <Trial />
							</FreeTrialWrapper>
						</ExaminePlusLink>
					}
				>
					<ExaminePlusLink
						trackDetails={{
							location,
							label: "get started with Examine+ today",
							trackFor: "sale",
						}}
						className="text-primary !underline hover:opacity-60"
					>
						get Examine+ for over {percentOff} off during our {saleLabel}
					</ExaminePlusLink>
				</SaleWrapper>
				.
			</p>
		</span>
	);
};

export { LockBadgeOverlay, LockBadge, LockIcon, SSOverlayCta };
