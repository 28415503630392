import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const tableVariants = cva("", {
	variants: {
		variant: {
			grid: "grid-table",
			default: "",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

const tableRowVariants = cva("md:divide-x", {
	variants: {
		variant: {
			simple: "sm:hover:bg-examine-purple-100",
			striped: "even:bg-white odd:bg-extra-gray-500",
		},
	},
	defaultVariants: {
		variant: "simple",
	},
});

const Table = ({
	className,
	variant,
	...props
}: React.HTMLAttributes<HTMLTableElement> & VariantProps<typeof tableVariants>) => (
	<div className="relative w-full overflow-auto">
		<table
			className={cn(tableVariants({ variant }), "w-full rounded-lg border", className)}
			{...props}
		/>
	</div>
);
Table.displayName = "Table";

const TableHeader = ({ className, ...props }: React.HTMLAttributes<HTMLTableSectionElement>) => (
	<thead className={className} {...props} />
);
TableHeader.displayName = "TableHeader";

const TableBody = ({ className, ...props }: React.HTMLAttributes<HTMLTableSectionElement>) => (
	<tbody className={cn("divide-y divide-gray-200", className)} {...props} />
);
TableBody.displayName = "TableBody";

const TableFooter = ({ className, ...props }: React.HTMLAttributes<HTMLTableSectionElement>) => (
	<tfoot className={cn("text-primary-foreground bg-primary font-medium", className)} {...props} />
);
TableFooter.displayName = "TableFooter";

const TableRow = ({
	className,
	variant,
	...props
}: React.HTMLAttributes<HTMLTableRowElement> & VariantProps<typeof tableRowVariants>) => (
	<tr className={cn(tableRowVariants({ variant }), className)} {...props} />
);
TableRow.displayName = "TableRow";

const TableHead = ({ className, ...props }: React.ThHTMLAttributes<HTMLTableCellElement>) => (
	<th
		className={cn(
			"border-none bg-primary p-3 text-13 font-medium text-white last:!border-r sm:px-0 sm:py-5 sm:text-base sm:font-normal",
			className
		)}
		{...props}
	/>
);
TableHead.displayName = "TableHead";

const TableCell = ({ className, ...props }: React.TdHTMLAttributes<HTMLTableCellElement>) => (
	<td className={cn("p-4 align-middle", className)} {...props} />
);
TableCell.displayName = "TableCell";

const TableCaption = ({ className, ...props }: React.HTMLAttributes<HTMLTableCaptionElement>) => (
	<caption
		className={cn("text-muted-foreground mt-4 text-sm", "border-examine-purple-100", className)}
		{...props}
	/>
);
TableCaption.displayName = "TableCaption";

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
