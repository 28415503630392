import { hasCookie } from "cookies-next";
import { NotebookPen } from "lucide-react";
import { useRouter } from "next/navigation";
import { useActionState, useCallback, useEffect, useRef, useState } from "react";
import { Page } from "types";

import { deleteNote, getNote, saveNote } from "@/app/actions/notes/actions";
import { Spinner } from "@/components/icons/spinner";
import { Button, type ButtonProps } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { Note } from "@/types/notes";

interface AddNoteProps extends ButtonProps {
	page: Page;
}

export function AddNote({ page, className, ...buttonProps }: AddNoteProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [existingNote, setExistingNote] = useState<Note | null>(null);
	const [noteContent, setNoteContent] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const formRef = useRef<HTMLFormElement>(null);
	const router = useRouter();

	const [state, saveAction] = useActionState(saveNote, {
		success: false,
		note: null,
		error: null,
	});

	const handleAddNote = () => {
		if (!hasCookie("token")) {
			router.push("/signup/?source=add_note");
			return;
		}
		setIsModalOpen(true);
	};
	const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNoteContent(e.target.value);
	};

	const handleDeleteNote = async () => {
		const formData = new FormData();
		formData.append("noteId", existingNote.id);
		const result = await deleteNote(null, formData);
		if (result.success) {
			setExistingNote(null);
			setNoteContent("");
			toast({
				title: "Note deleted",
				description: "Your note has been successfully deleted.",
				variant: "success",
			});
		} else {
			toast({
				title: "Error",
				description: result.error || "Failed to delete note.",
				variant: "error",
			});
		}
		setIsModalOpen(false);
	};

	const closeAndSave = async () => {
		if (noteContent.trim() === "") {
			if (existingNote) {
				await handleDeleteNote();
			} else {
				setIsModalOpen(false);
			}
			return;
		}

		if (existingNote?.content.trim() === noteContent.trim()) {
			setIsModalOpen(false);
			return;
		}

		if (!formRef.current) return;

		const formData = new FormData(formRef.current);
		const result = await saveNote(state, formData);
		if (result.success) {
			setExistingNote(result.note);
			toast({
				title: "Note saved",
				description: "Your note has been successfully saved.",
				variant: "success",
			});
		} else if (result.error) {
			toast({
				title: "Error",
				description: result.error,
				variant: "error",
			});
		}
		setIsModalOpen(false);
	};
	const handleDialogOpenChange = (open: boolean) => {
		if (!open) {
			closeAndSave();
		} else {
			fetchExistingNote();
			setIsModalOpen(true);
		}
	};

	const fetchExistingNote = useCallback(async () => {
		setIsLoading(true);
		const result = await getNote(page.type, page.id);
		if (result.success && result.note) {
			setExistingNote(result.note);
			setNoteContent(result.note.content);
		}
		setIsLoading(false);
	}, [page.type, page.id]);

	useEffect(() => {
		fetchExistingNote();
	}, [fetchExistingNote]);

	return (
		<>
			<Button
				className={cn(
					"flex items-center justify-center gap-x-2 px-4 py-2 font-medium md:w-full",
					className
				)}
				onClick={handleAddNote}
				disabled={isLoading}
				{...buttonProps}
			>
				<NotebookPen className="size-4 lg:size-5" />
				<span className="hidden md:inline">{existingNote ? "View note" : "Add note"}</span>
				{isLoading && <Spinner color="gray" size="4" />}
			</Button>

			{isModalOpen && (
				<Dialog open={isModalOpen} onOpenChange={handleDialogOpenChange}>
					<DialogContent className="flex h-[60vh] flex-col sm:h-auto sm:max-w-xl">
						<DialogHeader>
							<DialogTitle>{existingNote ? "Edit Note" : "Add Note"}</DialogTitle>
						</DialogHeader>
						<form ref={formRef} action={saveAction} className="flex grow flex-col">
							<div className="flex grow flex-col items-center justify-center gap-4 overflow-auto py-8">
								<Textarea
									name="noteContent"
									placeholder="Enter your note here..."
									className="h-full min-h-[160px] w-full rounded-md border-2 border-purple-300 p-2"
									defaultValue={existingNote?.content || ""}
									value={noteContent}
									onChange={handleNoteChange}
								/>
								<input type="hidden" name="pageType" value={page.type} />
								<input type="hidden" name="pageId" value={page.id} />
								<input type="hidden" name="pageUrl" value={page.url} />
							</div>
							<DialogFooter className="space-y-2 space-y-reverse md:space-y-0">
								<DialogClose>
									<Button type="button">{existingNote ? "Update note" : "Save note"}</Button>
								</DialogClose>
							</DialogFooter>
						</form>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}
