"use client";

import { useSettings } from "@lib/hooks/use-settings";

export default function Trial({ showDash = false }: { showDash?: boolean }): string {
	const { settings } = useSettings();
	const trialDays = settings?.trial || 7;

	if (showDash) {
		return `${trialDays}-day`;
	}

	return `${trialDays} day${trialDays === 1 ? "" : "s"}`;
}
